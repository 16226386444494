<template>
  <div>
    <ul class="statistics-counts">
      <li class="sc-item" v-for="item in statisticsCounts" :key="item.Name">
        <h2>{{ item.Name }}</h2>
        <span>{{ item.Value }}</span>
      </li>
    </ul>
    <el-row :gutter="20">
      <el-col :span="12">
        <el-tabs type="border-card">
          <el-tab-pane label="公司制度">
            <ul class="rules-list">
              <li v-for="item in companyNotice" :key="item.id" @click="viewNotice(item)">
                <el-link type="primary">{{ item.notice_title }}</el-link>
                <span>{{ item.addtime | dateTimeFilter }}</span>
              </li>
              <div class="no-data" v-show="!companyNotice.length">暂无数据</div>
            </ul>
          </el-tab-pane>
          <el-tab-pane label="公告">
            <ul class="rules-list">
              <li v-for="item in companyNoticeNotif" :key="item.id" @click="viewNotice(item)">
                <el-link type="primary">{{ item.notice_title }}</el-link>
                <span>{{ item.addtime | dateTimeFilter }}</span>
              </li>
              <div class="no-data" v-show="!companyNoticeNotif.length">暂无数据</div>
            </ul>
          </el-tab-pane>
        </el-tabs>
      </el-col>
      <el-col :span="12">
        <el-tabs type="border-card">
          <el-tab-pane label="待办任务">
            <ul class="rules-list">
              <li v-for="item in tasks" :key="item.pk_Process" @click="dotask(item)">
                <el-link type="primary">{{ item.Title }}</el-link>
                <span>{{ item.ModifyDate | dateTimeFilter }}</span>
              </li>
              <div class="no-data" v-show="!tasks.length">暂无数据</div>
            </ul>
          </el-tab-pane>
          <el-tab-pane label="我的消息">
            <ul class="rules-list">
              <li v-for="item in companyNoticemy" :key="item.id" @click="viewNotice(item)">
                <el-link type="primary">{{ item.notice_title }}</el-link>
                <span>{{ item.addtime | dateTimeFilter }}</span>
              </li>
              <div class="no-data" v-show="!companyNotice.length">暂无数据</div>
            </ul>
          </el-tab-pane>
        </el-tabs>
      </el-col>
    </el-row>
    <el-tabs type="border-card" style="margin-top: 10px">
      <el-tab-pane label="排行榜" style="padding: 10px 20px">
        <el-row :gutter="20">
          <el-col :span="12">
            <echart width="600px" :options="schoolRecruitRankOptions" id="schoolRecruitRank"></echart>
          </el-col>
          <el-col :span="12">
            <echart width="600px" :options="schoolRenewRankOptions" id="schoolRenewRank"></echart>
          </el-col>
        </el-row>
      </el-tab-pane>
    </el-tabs>
    <el-tabs type="border-card" style="margin-top: 10px">
      <el-tab-pane label="经营情况" style="padding: 10px 20px">
        <el-row :gutter="20">
          <el-col :span="8">
            <echart width="400px" :options="schoolCompleteRate_New" id="schoolCompleteRate_New"></echart>
          </el-col>
          <el-col :span="8">
            <echart width="400px" :options="schoolCompleteRate_Old" id="schoolCompleteRate_Old"></echart>
          </el-col>
          <el-col :span="8">
            <echart width="400px" :options="schoolCompleteRate_Other" id="schoolCompleteRate_Other">
            </echart>
          </el-col>
        </el-row>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
  import BarY from "@/components/echarts/BarY";
  import Echart from "@/components/echarts/Echart";
  export default {
    components: {
      BarY,
      Echart,
    },
    data() {
      return {
        companyRules: [],
        companyNotice: [],
        companyNoticeNotif: [],
        companyNoticemy: [],
        tasks: [],
        messages: [],
        statisticsCounts: [],
        schoolRecruitRankOptions: {
          title: {
            text: "各校区招生排行",
          },
          tooltip: {
            trigger: "axis",
            axisPointer: {
              type: "shadow",
            },
          },
          
          grid: [{
            // top: 75,
            // width: '400',
            // bottom: '45%',
            // left: -20,
             containLabel: true,
          }],
          legend: {
            data: ["各校区招生排行"],
          },
          xAxis: {
            type: "value",
            boundaryGap: [0, 0.01],
          },
          yAxis: {
            type: "category",
            data: [],
          },
          series: [
            {
              name: "招生",
              type: "bar",
              data: [],
            },
          ],
        },
        schoolRenewRankOptions: {
          title: {
            text: "各校区缴费排行",
          },
          tooltip: {
            trigger: "axis",
            axisPointer: {
              type: "shadow",
            },
          },
          legend: {
            data: ["各校区缴费排行"],
            textStyle: {
              fontSize: 12//字体大小

            }
          },
          xAxis: {
            type: "value",
            boundaryGap: [0, 0.01],
             
          }, 
          
          grid: [{
            // top: 75,
            // width: '400',
            // bottom: '45%',
            // left: -20,
             containLabel: true,
          }],
          yAxis: {
            type: "category",
            data: [],
            axisLabel: {
              // rotate:30,
              align:'right',  
            },
          },
          series: [
            {
              name: "缴费",
              type: "bar",
              data: [],
            },
          ],
        },
        schoolCompleteRate_New: {
          legend: {
            top: "bottom",
          },
          series: [
            {
              name: "学生",
              type: "pie",
              radius: [20, 120],
              center: ["50%", "50%"],
              // roseType: "area",
              itemStyle: {
                borderRadius: 8,
              },
              data: [],
            },
          ],
        },
        schoolCompleteRate_Old: {
          legend: {
            top: "bottom",
          },
          series: [
            {
              name: "缴费",
              type: "pie",
              radius: [20, 120],
              center: ["50%", "50%"],
              // roseType: "area",
              itemStyle: {
                borderRadius: 8,
              },
              data: [],
            },
          ],
        },
        schoolCompleteRate_Other: {
          legend: {
            top: "bottom",
          },
          series: [
            {
              name: "退费",
              type: "pie",
              radius: [20, 120],
              center: ["50%", "50%"],
              // roseType: "area",
              itemStyle: {
                borderRadius: 8,
              },
              data: [],
            },
          ],
        },
      };
    },
    created() {
      this.getProcessList();
      this.getNoticelist();
      this.GetSchoolsStudent();
      this.GetSchoolsMoney();
      this.getStatisticsCounts();
    },
    methods: {
      dotask(data) {
        var _this = this;
        layer.open({
          id: 'cktask',
          title: '待办事项',
          area: ['95%', '85%'],
          maxmin: true,
          skin: 'layui-layer-demo', //样式类名
          content: '/Process/TaskExamin.aspx?id=' + data.pk_Process,
          shade: 0.3,
          btn: ['关闭'],
          callBack: function (iframeId, _index) {
            layer.closeAll();
          },
          type: 2,
          success: function (o, i) {

          },
          end: function () {
            _this.getProcessList();
          },
        });
      },
      viewNotice(item) {
        this.$Dialog.ViewNotice({
          id: item.id,
        });
      },
      getStatisticsCounts() {
        this.API.GetStatisticsIndexCount().then((res) => {
          this.statisticsCounts = res.data;
        });
      },
      GetSchoolsStudent() {
        this.API.GetSchoolsStudent({ limit: 6 }).then((res) => {
          let list = res.data;
          this.schoolRecruitRankOptions.yAxis.data = list.map((t) => t.Name);
          this.schoolRecruitRankOptions.series[0].data = list.map((t) => t.Value);
          this.schoolRecruitRankOptions = JSON.parse(
            JSON.stringify(this.schoolRecruitRankOptions)
          );

          this.schoolCompleteRate_New.series[0].data = list.map((t) => {
            return {
              value: t.Value,
              name: t.Name,
            };
          });
          this.schoolCompleteRate_New = JSON.parse(
            JSON.stringify(this.schoolCompleteRate_New)
          );
        });
      },
      GetSchoolsMoney() {
        this.API.GetSchoolsMoney({ limit: 6 }).then((res) => {
          let list = res.data;
          this.schoolRenewRankOptions.yAxis.data = list.map((t) => t.Name);
          this.schoolRenewRankOptions.series[0].data = list.map((t) => t.Value);
          this.schoolRenewRankOptions = JSON.parse(
            JSON.stringify(this.schoolRenewRankOptions)
          );

          this.schoolCompleteRate_Old.series[0].data = list.map((t) => {
            return {
              value: t.Value,
              name: t.Name,
            };
          });
          this.schoolCompleteRate_Old = JSON.parse(
            JSON.stringify(this.schoolCompleteRate_Old)
          );
          console.log("this.schoolCompleteRate_Old", this.schoolCompleteRate_Old);
        });
      },
      GetSchoolsRefundMoney() {
        this.API.GetSchoolsRefundMoney({ limit: 6 }).then((res) => {
          let list = res.data;
          this.schoolCompleteRate_Other.series[0].data = list.map((t) => {
            return {
              value: t.Value,
              name: t.Name,
            };
          });

          this.schoolCompleteRate_Other = JSON.parse(
            JSON.stringify(this.schoolCompleteRate_Other)
          );
        });
      },
      getProcessList() {
        this.API.getProcessList({
          pageindex: 1,
          pagesize: 6,
        }).then((res) => {
          this.tasks = res.data;
        });
      },
      getNoticelist() {
        this.API.GetNoticelist({
          pageindex: 1,
          pagesize: 6,
          // nogid:['45760801-87AD-4F0C-A72D-FC81D2407C88']
          gid: 'ED1D32E0-0A45-4018-B563-C5D084555B33'
        }).then((res) => {
          this.companyNotice = res.data.rows;
        });

        this.API.GetNoticelist({
          pageindex: 1,
          pagesize: 6,
          gid: '45760801-87AD-4F0C-A72D-FC81D2407C88'
        }).then((res) => {
          this.companyNoticeNotif = res.data.rows;
        });

        this.API.GetNoticelist({
          pageindex: 1,
          pagesize: 6,
          ismy: true,
        }).then((res) => {
          this.companyNoticemy = res.data.rows;
        });
      },
    },
  };
</script>
<style lang="scss" scoped>
  .statistics-counts {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .sc-item {
      flex: 1;
      margin-right: 10px;
      margin-bottom: 10px;
      padding: 20px;
      min-width: 150px;
      border-radius: 5px;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
      color: #fff;
      cursor: pointer;

      &:nth-child(3n) {
        background-color: #409eff;
      }

      &:nth-child(3n + 1) {
        background-color: #67c23a;
      }

      &:nth-child(3n + 2) {
        background-color: #e6a23c;
      }

      h2 {
        font-size: 18px;
        font-weight: lighter;
      }

      span {
        display: block;
        margin-top: 10px;
        font-size: 30px;
        font-weight: bold;
      }
    }
  }

  .rules-list {
    min-height: 150px;
    padding: 0 10px;

    li {
      height: 35px;
      display: flex;
      justify-items: center;
      justify-content: space-between;
    }
  }
</style>
<style scoped>
  >>>.right-content-content {
    background-color: #f00 !important;
    box-shadow: none !important;
  }
</style>